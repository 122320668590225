import { BrowserRouter as Router } from "react-router-dom";
import WhatsappForm from './components/whatsappForm';

function App() {
  return (
    <Router>
      <WhatsappForm />
      </Router>
  );
}

export default App;
