import { TextField } from "@mui/material";

interface Props {
  label: string,
  value?: string,
  className?: string,
  multiline?: boolean,
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string) => void,
}

const InputText = (props: Props) => {
  const { label, value, className, multiline, onChange } = props;
  return (
    <TextField
      label={label}
      variant="outlined"
      className={`m-1 ${className}`}
      size="small"
      value={value}
      multiline={multiline}
      onChange={(event) => onChange && onChange(event.target.value)}
      fullWidth
    />
  );
};

export default InputText;
