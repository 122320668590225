import { useState } from "react";
import InputText from "./shared/InputText";

const WhatsappForm = () => {
  const [phone, setPhone] = useState('');

  const handleWrite = () => {
    window.open(`https://wa.me/${phone}`,'_blank', 'rel=noopener noreferrer')
  };

  return (
    <div className="row text-center d-flex justify-content-center m-0 p-0">
      <div className="col-12 col-md-8 m-2 p-2">
        <form>
          <div className="mb-3">
            <InputText label="" value={phone} onChange={(value: string) => setPhone(value)} />
          </div>
          <button type="button" className="btn btn-primary" onClick={handleWrite}>Escribir</button>
        </form>
      </div>
    </div>
  );
}

export default WhatsappForm;
